
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as driversBzebRKmqnWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue?macro=true";
import { default as indexGVHGTSwFxvMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue?macro=true";
import { default as index1ioKyqHhlRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as editBRTL0IlKNWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue?macro=true";
import { default as indexFI20eXoLxkMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue?macro=true";
import { default as createvVru6tvduRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue?macro=true";
import { default as indexkb9q08CEfmMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue?macro=true";
import { default as edit7ektvZ1UQWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue?macro=true";
import { default as indexcEIXibweFvMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/index.vue?macro=true";
import { default as createZo5sN2RPm1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue?macro=true";
import { default as index80Hg2z8rP2Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue?macro=true";
import { default as editgeCzTtnSvZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue?macro=true";
import { default as indexxxioRYl9GuMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue?macro=true";
import { default as indexGLRAsya0qWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue?macro=true";
import { default as editHshr6W1dkCMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/edit.vue?macro=true";
import { default as indextQRISQ7ywtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue?macro=true";
import { default as _91id_93NhZ9sxf3fzMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/[id].vue?macro=true";
import { default as indexWuWRFeNH8gMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/index.vue?macro=true";
import { default as _91id_930H5aFyIPI7Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/[id].vue?macro=true";
import { default as indexBCXlmWhlaPMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/index.vue?macro=true";
import { default as edites8ufIPx6eMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue?macro=true";
import { default as indexqUsNTq6DYNMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue?macro=true";
import { default as indexISzk7U56CCMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/index.vue?macro=true";
import { default as indexFYpBU2VUxtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue?macro=true";
import { default as _91id_93PIqyA40gXXMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/[id].vue?macro=true";
import { default as index7ZRyo8Vw2VMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/index.vue?macro=true";
import { default as _91id_9383jk5OsdFyMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/[id].vue?macro=true";
import { default as indexHOOPpV64QIMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/index.vue?macro=true";
import { default as fleet_45requirediPCk1K3oVfMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/fleet-required.vue?macro=true";
import { default as editBvGWq0gLKTMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/[id]/edit.vue?macro=true";
import { default as indexlTNECJJ6qkMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/[id]/index.vue?macro=true";
import { default as create1ZXU5HRbKpMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/create.vue?macro=true";
import { default as indexAzHswwG5XlMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/index.vue?macro=true";
import { default as indexcRn5YhvuWDMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/fleets/[fleetId]/statements/[statementId]/index.vue?macro=true";
import { default as indexlAlrOY1Ha1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue?macro=true";
import { default as index65IfXXtfMJMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/weekly-access-fees/[id]/index.vue?macro=true";
import { default as indexLIurEUEoChMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/weekly-access-fees/index.vue?macro=true";
import { default as loginh8fMddjfe6Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue?macro=true";
import { default as editGCartPr1ViMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/edit.vue?macro=true";
import { default as indexAcKfr6BYDFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue?macro=true";
import { default as indexW5glvySWQJMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/[id]/index.vue?macro=true";
import { default as index7uT36MSUwbMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/index.vue?macro=true";
import { default as createsYLZFGjR5sMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/create.vue?macro=true";
import { default as indexWd6uFtocItMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue?macro=true";
import { default as indexnrmra44dkFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue?macro=true";
import { default as editEpz5RLwlaGMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/fleets/edit.vue?macro=true";
import { default as indexGG8XycWEzfMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/index.vue?macro=true";
import { default as editJhiHTDHnLJMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/roles/edit.vue?macro=true";
import { default as indexMQvTx22C0JMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/index.vue?macro=true";
export default [
  {
    name: "agreements-id-drivers",
    path: "/agreements/:id()/drivers",
    meta: driversBzebRKmqnWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue")
  },
  {
    name: "agreements-id",
    path: "/agreements/:id()",
    meta: indexGVHGTSwFxvMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue")
  },
  {
    name: "agreements",
    path: "/agreements",
    meta: index1ioKyqHhlRMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue")
  },
  {
    name: "documents-id-edit",
    path: "/documents/:id()/edit",
    meta: editBRTL0IlKNWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue")
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    meta: indexFI20eXoLxkMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue")
  },
  {
    name: "documents-create",
    path: "/documents/create",
    meta: createvVru6tvduRMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexkb9q08CEfmMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue")
  },
  {
    name: "documents-types-id-edit",
    path: "/documents/types/:id()/edit",
    meta: edit7ektvZ1UQWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue")
  },
  {
    name: "documents-types-id",
    path: "/documents/types/:id()",
    meta: indexcEIXibweFvMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/index.vue")
  },
  {
    name: "documents-types-create",
    path: "/documents/types/create",
    meta: createZo5sN2RPm1Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue")
  },
  {
    name: "documents-types",
    path: "/documents/types",
    meta: index80Hg2z8rP2Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue")
  },
  {
    name: "documents-vehicle-changes-id-edit",
    path: "/documents/vehicle-changes/:id()/edit",
    meta: editgeCzTtnSvZMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue")
  },
  {
    name: "documents-vehicle-changes-id",
    path: "/documents/vehicle-changes/:id()",
    meta: indexxxioRYl9GuMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue")
  },
  {
    name: "documents-vehicle-changes",
    path: "/documents/vehicle-changes",
    meta: indexGLRAsya0qWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue")
  },
  {
    name: "driver-partners-id-edit",
    path: "/driver-partners/:id()/edit",
    meta: editHshr6W1dkCMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/edit.vue")
  },
  {
    name: "driver-partners-id",
    path: "/driver-partners/:id()",
    meta: indextQRISQ7ywtMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue")
  },
  {
    name: "driver-partners-bookings-id",
    path: "/driver-partners/bookings/:id()",
    meta: _91id_93NhZ9sxf3fzMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/[id].vue")
  },
  {
    name: "driver-partners-bookings",
    path: "/driver-partners/bookings",
    meta: indexWuWRFeNH8gMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/index.vue")
  },
  {
    name: "driver-partners-earnings-id",
    path: "/driver-partners/earnings/:id()",
    meta: _91id_930H5aFyIPI7Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/[id].vue")
  },
  {
    name: "driver-partners-earnings",
    path: "/driver-partners/earnings",
    meta: indexBCXlmWhlaPMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/index.vue")
  },
  {
    name: "driver-partners-exemptions-id-edit",
    path: "/driver-partners/exemptions/:id()/edit",
    meta: edites8ufIPx6eMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue")
  },
  {
    name: "driver-partners-exemptions-id",
    path: "/driver-partners/exemptions/:id()",
    meta: indexqUsNTq6DYNMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue")
  },
  {
    name: "driver-partners-exemptions",
    path: "/driver-partners/exemptions",
    meta: indexISzk7U56CCMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/index.vue")
  },
  {
    name: "driver-partners",
    path: "/driver-partners",
    meta: indexFYpBU2VUxtMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue")
  },
  {
    name: "driver-partners-payouts-id",
    path: "/driver-partners/payouts/:id()",
    meta: _91id_93PIqyA40gXXMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/[id].vue")
  },
  {
    name: "driver-partners-payouts",
    path: "/driver-partners/payouts",
    meta: index7ZRyo8Vw2VMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/index.vue")
  },
  {
    name: "driver-partners-receipts-id",
    path: "/driver-partners/receipts/:id()",
    meta: _91id_9383jk5OsdFyMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/[id].vue")
  },
  {
    name: "driver-partners-receipts",
    path: "/driver-partners/receipts",
    meta: indexHOOPpV64QIMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/index.vue")
  },
  {
    name: "fleet-required",
    path: "/fleet-required",
    meta: fleet_45requirediPCk1K3oVfMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/fleet-required.vue")
  },
  {
    name: "invoices-charges-id-edit",
    path: "/invoices/charges/:id()/edit",
    meta: editBvGWq0gLKTMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/[id]/edit.vue")
  },
  {
    name: "invoices-charges-id",
    path: "/invoices/charges/:id()",
    meta: indexlTNECJJ6qkMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/[id]/index.vue")
  },
  {
    name: "invoices-charges-create",
    path: "/invoices/charges/create",
    meta: create1ZXU5HRbKpMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/create.vue")
  },
  {
    name: "invoices-charges",
    path: "/invoices/charges",
    meta: indexAzHswwG5XlMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/charges/index.vue")
  },
  {
    name: "invoices-fleets-fleetId-statements-statementId",
    path: "/invoices/fleets/:fleetId()/statements/:statementId()",
    meta: indexcRn5YhvuWDMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/fleets/[fleetId]/statements/[statementId]/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexlAlrOY1Ha1Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue")
  },
  {
    name: "invoices-weekly-access-fees-id",
    path: "/invoices/weekly-access-fees/:id()",
    meta: index65IfXXtfMJMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/weekly-access-fees/[id]/index.vue")
  },
  {
    name: "invoices-weekly-access-fees",
    path: "/invoices/weekly-access-fees",
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/weekly-access-fees/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginh8fMddjfe6Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue")
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    meta: editGCartPr1ViMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/edit.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: indexAcKfr6BYDFMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue")
  },
  {
    name: "news-categories-id",
    path: "/news/categories/:id()",
    meta: indexW5glvySWQJMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/[id]/index.vue")
  },
  {
    name: "news-categories",
    path: "/news/categories",
    meta: index7uT36MSUwbMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/index.vue")
  },
  {
    name: "news-create",
    path: "/news/create",
    meta: createsYLZFGjR5sMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/create.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexWd6uFtocItMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexnrmra44dkFMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue")
  },
  {
    name: "users-id-fleets-edit",
    path: "/users/:id()/fleets/edit",
    meta: editEpz5RLwlaGMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/fleets/edit.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexGG8XycWEzfMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-roles-edit",
    path: "/users/:id()/roles/edit",
    meta: editJhiHTDHnLJMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/roles/edit.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexMQvTx22C0JMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/index.vue")
  }
]